const colors = {
  // 컬러 예시
  mainBlue: "#3478F6",
  white100: "#FFFFFF",
  white90: "#FEFEFE",
  white80: "#FAFAFA",
  white60: "#F2F2F2",
  white50: "#F3F3F3",
  black100: "#000000",
  black90: "#2F2F2F",
  black85: "#5F5F5F",
  black80: "#6D6D6D",
  black60: "#ababab",
  blue40: "#E3EFFF",
  blue20: "#EFF5FD",
  gray: "#C4C4C4",
  gray01: "#2F2F2F",
  gray02: "#6D6D6D",
  gray03: "#C3C3C3",
  gray_tag: "#899294",
  bg: "#F0F1F4",
  yellow: "#FEE500",
  subYellow: "#FFC121",
  subPink: "#FF9999",
  accent: "#2F6EFF",
} as const;

export default colors;

export type ColorsType = typeof colors[keyof typeof colors];
