import {
  useEffect,
  type MouseEventHandler,
  type PropsWithChildren,
} from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

type Props = {
  closeModal: () => void;
};

const Modal = ({ children, closeModal }: PropsWithChildren<Props>) => {
  const onClickBackdrop = () => {
    closeModal();
  };

  const preventCloseModal: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);

  return createPortal(
    <Backdrop onClick={onClickBackdrop}>
      <ModalContainer onClick={preventCloseModal}>{children}</ModalContainer>
    </Backdrop>,
    document.getElementById("modal-root") as HTMLElement
  );
};

export default Modal;

const Backdrop = styled.div`
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 300;
  height: 100dvh;
`;

const ModalContainer = styled.div`
  width: 335px;
  border-radius: 20px;
  background-color: white;
`;
