const GREY_SCALE_COLOR = {
  greyScale0: "#FFFFFF",
  greyScale1: "#F9FAFA",
  greyScale2: "#F1F2F4",
  greyScale3: "#DDDFE3",
  greyScale4: "#C3C5CB",
  greyScale5: "#9FA1A8",
  greyScale6: "#686B73",
  greyScale7: "#474A52",
  greyScale8: "#1E2024",
  greyScale9: "#000000",
} as const;

const BLUE_COLOR = {
  blue50: "#F5FAFF",
  blue100: "#EBF5FF",
  blue300: "#99C5FF",
  blue500: "#4785FF",
  blue700: "#2F6EFF",
  blue900: "#1750D3",
} as const;

const RED_COLOR = {
  red50: "#FFFAFA",
  red100: "#FFF0F0",
  red300: "#FFB8B8",
  red500: "#FF7070",
  red700: "#FF4747",
  red900: "#E82C2C",
} as const;

const YELLOW_COLOR = {
  yellowKakao: "#F9E54D",
  yellow50: "#FFFCF0",
  yellow100: "#FFF8DB",
  yellow300: "#FFEDA3",
  yellow500: "#FFE066",
  yellow700: "#FFCC00",
  yellow900: "#FFB200",
} as const;

const EVENT_COLOR = {
  geneticTestOrange: "#F5510A",
};

const GREEN_COLOR = {
  green50: "#F0FFF5",
  green100: "#E5FAEC",
  green300: "#B2F0CC",
  green500: "#66E0A3",
  green700: "#00C785",
  green900: "#00AD74",
} as const;

const LEGACY_COLOR = {
  mainBlueDisabled: "#AEC9FB",
  blue40: "#E3EFFF",
  gray: "#C4C4C4",
  fadeGray: "#B5BDCD",
  black90: "#2F2F2F",
  black80: "#6D6D6D",
  black60: "#ABABAB",
  white60: "#F2F2F2",
} as const;

const SEMANTIC_COLOR = {
  // FILL
  accent: BLUE_COLOR["blue700"],
  black: GREY_SCALE_COLOR["greyScale8"],
  dark: GREY_SCALE_COLOR["greyScale7"],
  medium: GREY_SCALE_COLOR["greyScale5"],
  mediumLight: GREY_SCALE_COLOR["greyScale3"],
  light: GREY_SCALE_COLOR["greyScale1"],
  white: GREY_SCALE_COLOR["greyScale0"],
  // BG
  bg: "#E9EBF0",
  bgPrimary: GREY_SCALE_COLOR["greyScale0"],
  secondary: GREY_SCALE_COLOR["greyScale2"],
  // BORDER
  divider: GREY_SCALE_COLOR["greyScale2"],
  borderLight: "#F1F2F4",
  borderMedium: GREY_SCALE_COLOR["greyScale3"],
  // LABEL
  lightBlue: BLUE_COLOR["blue100"],
  lightRed: RED_COLOR["red100"],
  lightGreen: GREEN_COLOR["green100"],
  lightYellow: YELLOW_COLOR["yellow100"],
  // STATE
  stateNegative: RED_COLOR["red700"],
  statePositive: GREEN_COLOR["green700"],
  stateWarning: YELLOW_COLOR["yellow700"],
  // DIM
  dimThick: "#22242AB2",
  dimMedium: "#22242a99",
  dimLight: "#22242a33",
  // DISABLED
  disabledBackground: GREY_SCALE_COLOR["greyScale2"],
  disabledForeground: GREY_SCALE_COLOR["greyScale4"],

  distructive: RED_COLOR["red700"],
} as const;

export const Color = {
  ...GREY_SCALE_COLOR,
  ...BLUE_COLOR,
  ...RED_COLOR,
  ...YELLOW_COLOR,
  ...GREEN_COLOR,
  ...SEMANTIC_COLOR,
  ...LEGACY_COLOR,
  ...EVENT_COLOR,
} as const;

export type ColorKey = keyof typeof Color;
